import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Components/Home';
import Preloader from './Components/Preloader';
import './App.css';

function App() {
  const [loading, setLoading] = useState(true);
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    document.addEventListener('contextmenu', handleContextMenu);

    // Simulate loading time and apply fade-out transition
    setTimeout(() => {
      setFadeOut(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000); // Match this time with the transition duration
    }, 2000); // Adjust the time as needed

    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  return (
    <BrowserRouter>
      {loading ? (
        <div className={`preloader ${fadeOut ? 'fade-out' : ''}`}>
          <Preloader />
        </div>
      ) : (
        <div className="main-content fade-in">
          <Routes>
            <Route path="/" element={<Home />} />
          </Routes>
        </div>
      )}
    </BrowserRouter>
  );
}

export default App;
