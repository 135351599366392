import React from 'react'

const Education = () => {
    const Event_scedule=[
        {
            number:1,
            year:"2021-present",
            Institute:"Academy Of Technology",
            Course:"B Tech (ECE)"
        },
        {
            number:2,
            year:"2019-2021",
            Institute:"CDMHS",
            Course:"Class XI-XII"
        },
        {
            number:3,
            year:"2009-2019",
            Institute:"CDMHS",
            Course:"Class I-X"
        },      
    ];

     
  return (
    <div className='flex flex-col items-center justify-center pt-12'>
    <h1  className=' text-[40px] flex items-center justify-center' >Education</h1>
    <div className="lg hidden lg:flex flex-col items-center justify-center">
    <div className="pad  pt-12"></div>
    {Event_scedule.map((event, index) => (
        <div key={index} className='flex p-2 gap-2 lg:gap-6 text-white lg:text-[20px] items-center justify-center w-[90%]' >
            <div data-aos="fade-right" data-aos-duration="1500"   className={`left text-right flex flex-col h-auto lg:h-[150px] w-[400px] rounded-[15px] border border-[5px] p-4 mt-[-120px] lg:mt-[-40px] mt-[-250px] hover:bg-white hover:text-black ${event.number % 2 === 0 ? 'hidden' : ''}`}>
                <div>{event.year}</div>
                <div>{event.Course}</div>
                <div>{event.Institute}</div>
            </div>
            <div className={`left  h-[250px] w-[400px] p-4 ${event.number % 2 === 1 ? 'hidden' : ''}`}>
                
            </div>

            <div className="mid_portion flex flex-col items-center justify-center">
                <div className="middle_round border border-white hover:bg-white   border-[3px] h-[50px] w-[50px] rounded-[50%]">

                </div>
                <div className="middle_stick border border-[3px] h-[400px] lg:h-[200px] w-[0px]" data-aos="zoom-in-down" data-aos-duration="1500" >

                </div>
            </div> 
            <div data-aos="fade-left" data-aos-duration="1500"  className={`left hover:bg-white hover:text-black flex flex-col  border border border-[5px] rounded-[15px] h-auto lg:h-[150px] w-[400px]  p-4 mt-[-120px] lg:mt-[-40px] mt-[-250px] ${event.number % 2 === 1 ? 'hidden' : ''}`}>
                <div>{event.year}</div>
                <div>{event.Course}</div>
                <div>{event.Institute}</div>
            </div>
            <div className={`left  h-[250px] w-[400px] p-4 ${event.number % 2 === 0 ? 'hidden' : ''}`}>
                
            </div>
        </div>
        ))}
        <div className="middle_round border  border-[5px] h-[50px] bg-white w-[50px] rounded-[50%]">

        </div>
    </div>
        <div className="sm lg:hidden">
        <div className="pad  pt-12"></div>
    {Event_scedule.map((event, index) => (
        <div key={index} className='flex p-2 gap-2 text-white '>
            
            <div className="mid_portion flex flex-col items-center justify-center">
                <div className="middle_round border border-white hover:bg-white   border-[3px] h-[50px] w-[50px] rounded-[50%]">

                </div>
                <div data-aos="flip-up" data-aos-duration="1500" className="middle_stick border border-[3px] h-[200px] lg:h-[200px] w-[0px]">

                </div>
            </div> 
            <div  data-aos="flip-right" data-aos-duration="1500" className="left hover:bg-white hover:text-black flex flex-col  border border border-[5px] rounded-[15px]  h-[150px] w-[200px]  p-4 ">
                <div>{event.year}</div>
                <div>{event.Course}</div>
                <div>{event.Institute}</div>
            </div>
        </div>
        ))}
        <div className="middle_round border  border-[5px] h-[50px] bg-white w-[50px] rounded-[50%] m-auto ml-[3%]">

        </div>
        </div>
    </div>
  )
}

export default Education
