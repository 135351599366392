import React, { useEffect } from 'react'
import Ritam from "./Assets/Ritam2.png"
import AOS from 'aos';
import 'aos/dist/aos.css';
const Achivements = () => {
    useEffect(()=>{
        AOS.init();
    },[])
   
        const Event_scedule=[
            {
                number:1,
                title:"winner",
                organisation:"InterHacktive",
                details:"Hackathon"
            },
            {
                number:2,
                title:"600+",
                organisation:"problems Solved",
                details:"On Leetcode"
            },
            {
                number:3,
                title:"Top 10%",
                organisation:"Globally (Heighest)",
                details:"On Leetcode"
            },
            
            
        ];
  return (
    <div className='flex flex-col items-center justify-center text-[40px] pt-12'>
      Achivements
      <div className="achivement_img border-b-[5px] rounded-[25px] lg:hidden flex items-center pt-8 justify-center" data-aos="fade-up" data-aos-duration="1500">
            <img src={Ritam} alt="" className='w-[350px] h-[400px]'/>
        </div>
      <div className="achivement_main flex items-center justify-center gap-24">
        <div className="achivement_details">
        <div className="sm ">
        <div className="pad  pt-12"></div>
    {Event_scedule.map((event, index) => (
        <div key={index} className='flex p-2 gap-2 text-white '>
            
            
            <div data-aos="flip-left" data-aos-duration="1500" className="left hover:bg-white hover:text-black flex flex-col  border border border-[5px] rounded-[15px]  h-[150px] w-[200px] lg:w-[400px]  p-4 text-[20px]">
                <div className='font-bold'>{event.title}</div>
                <div>{event.organisation}</div>
                <div>{event.details}</div>
            </div>
            <div className="mid_portion flex flex-col items-center justify-center">
                <div className="middle_round border border-white hover:bg-white   border-[5px] h-[50px] w-[50px] rounded-[50%]">

                </div>
                <div className="middle_stick border border-[3px] h-[200px] lg:h-[200px] w-[0px]" data-aos="flip-up"
     data-aos-duration="1500">

                </div>
            </div> 
        </div>
        ))}
        <div className="middle_round border m-auto mr-[10px] border-[5px] h-[50px] bg-white w-[50px] rounded-[50%]">

</div>
        </div>
        </div>
        <div className="achivement_img border-b-[5px] rounded-[25px] hidden lg:flex items-center justify-center" data-aos="fade-up" data-aos-duration="3000">
            <img src={Ritam} alt="" className='w-[570px] h-[700px]'/>
        </div>
      </div>
    </div>
  )
}

export default Achivements
