import React from 'react'
import Ritam from "./Assets/Ritam4.png"
const About = () => {
  return (
    <div className='flex flex-col items-center justify-center text-[40px] gap-12 pt-12'>
      About Me
      <div className="about_section flex flex-col lg:flex-row items-center justify-center gap-12 p-6" data-aos="zoom-in" data-aos-duration="1500">
       
        <div className="image border-[5px] rounded-[25px] lg:w-[20%]" >
            <img src={Ritam} alt="" className='rounded-[25px]'/>
        </div>
        
        <div className="details text-[20px] lg:w-[50%] text-left" >
            <p>Hello 👋, I'm Ritam Majumder, a versatile Full Stack Developer with a passion for Competitive Coding. Currently pursuing a B.Tech degree in Electronics and Communications Engineering from AOT (Academy Of Technology) under MAKAUT. I am adept at utilizing HTML, CSS, JavaScript, React.js, Next.js, TypeScript, Firebase, Node.js, Express.js, MySQL, and MongoDB. My expertise extends to <span className='font-bold'>Data Structures and Algorithms
                </span> .
                <br />
<br />
Over the course of my career, I've completed over 15 projects, spanning web applications, reflecting my commitment to delivering high-quality, user-friendly experiences.
<br />
<br />
My achievements in the software development arena include winning Hackathons and achieving high ratings on DSA platforms. I'm particularly proud of my top rankings on LeetCode, and I hold a 2-star rating on CodeChef, consistently performing in the top 10% globally on LeetCode. DSA problem-solving is my passion, with a strong grasp of algorithms like Backtracking, Dynamic Programming, BFS, DFS, Greedy, and Binary Search.
<br />
<br />
Apart from coding, I enjoy hobbies such as photography and videography, which provide me with refreshment and inspiration. 🎧</p>
        </div>
      </div>
    </div>
  )
}

export default About
