import React from 'react';
import { TypeAnimation } from 'react-type-animation';
import Navbar from './Navbar';
import './BG.css';
import Ritam from './Assets/Ritam1.png';
import Skills from './Skills';


import Projects from './Projects';
import Education from './Education';
import Contact from './Contact';
import ParticlesComponent from './Particle';
import Achivements from './Achivements';
import About from './About';

const Home = () => {
  return (
    <div className="homeBg w-full h-full text-white font-poppins">
      <ParticlesComponent id="particle"/>
      
      <Navbar />
      <div className="hero flex flex-col lg:flex-row lg:items-center gap-8 p-6" >
        <div className="heroimg flex  items-center justify-center lg:ml-[200px]" data-aos="fade-in" data-aos-duration="2500" >
          <img
            src={Ritam}
            alt="Ritam"
            className=" rounded-[50px] pt-6 pr-6 pl-6   border-b-[5px] border-[#e4dddd]"
          />
        </div>
        <div className="herodetail flex items-center justify-center flex-col lg:block ml-5 lg:ml-64">
          <div className="name text-4xl font-medium">
            Hi, I am <span className='text-[50px] color-[#e4dddd]' >Ritam</span>
          </div>
          <TypeAnimation
            sequence={[
              'Full Stack Developer',
              1000,
              'Problem Solver',
              1000,
            ]}
            wrapper="span"
            speed={200}
            style={{ fontSize: '30px', display: 'inline-block' }}
            repeat={Infinity}
          />
          <div className="btn-hire pt-4">
          <a href="https://drive.google.com/file/d/14qEOPynJvrVonYVK_FBf4TLH1pGxYbhx/view" target='_blank' className="ml-auto text-right pt-4">
        <button className="px-6 py-2 bg-white text-black rounded-lg font-bold transform hover:-translate-y-1 transition duration-400">
          Resume
        </button>
       </a>
          </div>
          
        </div>
        
      </div>
      <Skills />
      
      <Projects/>
      <About/>
      <Education/>
      <Achivements/>
      <Contact/>
    </div>
  );
};

export default Home;
