import React from 'react'
import { useState } from 'react';
import {CircularProgress} from "@nextui-org/react";

const Skills = () => {
  const [skill_Details,setSkill_Details]=useState([
    {
      Item:"HTML",
      Value:80,
    },
    {
      Item:"CSS",
      Value:75,
    }
    ,
    {
      Item:"JavaScript",
      Value:75,
    }
    ,
    {
      Item:"React Js",
      Value:80,
    }
    ,
    {
      Item:"Tailwind CSS",
      Value:75,
    }
  ]
)
  const percentage = 66;

  const Skill=[
    {
      Name:"FrontEnd",
      Details:[
        {
          Item:"HTML",
          Value:80,
        },
        {
          Item:"CSS",
          Value:75,
        }
        ,
        {
          Item:"JavaScript",
          Value:75,
        }
        ,
        {
          Item:"React Js",
          Value:80,
        }
        ,
        {
          Item:"Tailwind CSS",
          Value:75,
        }
      ]
    },
    {
      Name:"BackEnd",
      Details:[
        {
          Item:"Node Js",
          Value:75,
        },
        {
          Item:"Express Js",
          Value:70,
        },
        {
          Item:"Mongo DB",
          Value:70,
        },
        {
          Item:"SQL",
          Value:60,
        },
        
      ]
    },
    {
      Name:"Programing",
      Details:[
        {
          Item:"C++",
          Value:90,
        },
        {
          Item:"C",
          Value:75,
        },
        {
          Item:"JAVA",
          Value:50,
        },
        {
          Item:"Python",
          Value:50,
        },
        {
          Item:"RUST",
          Value:45,
        }
      ]
    },
    {
      Name:"Others",
      Details:[
        {
          Item:"DSA",
          Value:95,
        },
        {
          Item:"Computer Network",
          Value:75,
        },
        {
          Item:"OOP",
          Value:85,
        },{
          Item:"DBMS",
          Value:65,
        }
      ]
    },
  ]
  return (
    <div className='pt-12'>
      <div className="heading_skill text-[40px] flex justify-center items-ceter pb-12">
          Skills
        </div>
      <div className="total_skills flex flex-col pl-6 ">
        
        
        <div className="skill_template flex gap-24">
          <div className="skill_window_left flex flex-wrap sm:w-1/2 gap-6  ml-[-20px] lg:ml-[20px]">
            {Skill.map((skill, Id) => (
              <div className="btn_skill flex flex-col gap-6" data-aos="zoom-in" data-aos-duration="1500">
                <div className="px-6 py-2 border border-white hover:bg-white hover:text-black text-white rounded-lg font-bold transform hover:-translate-y-1 transition duration-400 w-[200px] lg:w-[300px] lg:h-[100px]" onMouseOver={()=>setSkill_Details(skill.Details)}
                        onMouseOut={() => {
                            console.log('Mouse left!');
                           
                          }}>
                  {skill.Name}
                </div>
                <div className="detail_div flex flex-wrap gap-6  lg:hidden pl-2 ">
                {skill.Details.map((detail,id)=>(
                  <div className="detail">
                    <CircularProgress
                      label={detail.Item}
                      size="lg"
                      value={detail.Value}
                      color="success"
                      showValueLabel={true}
                    />
                  </div>
                ))}
                </div>
                
                
              </div>
                
              
            ))}
            
          </div>
          <div className="detail_div flex gap-24 pt-4 hidden lg:flex" data-aos="zoom-out" data-aos-duration="1500">
                {skill_Details.map((detail,id)=>(
                  <div className="detail">
                    <CircularProgress
                      label={detail.Item}
                      size="lg"
                      value={detail.Value}
                      color="success"
                      showValueLabel={true}
                    />
                  </div>
                ))}
                </div>
          

        </div>
        
      </div>
    </div>
  )
}

export default Skills
