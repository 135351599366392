import React from 'react'
import Ritam from "./Assets/Ritam5.png"
import { FaLinkedin } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";
import { SiLeetcode } from "react-icons/si";
import { SiGmail } from "react-icons/si";
import { FaLink } from "react-icons/fa";
const Contact = () => {
    const Links=[
        {
            id:1,
            icon:FaLinkedin,
            url:"https://www.linkedin.com/in/ritam-majumder-krover/"
        },
        {   id:2,
            icon:FaGithub,
            url:"https://github.com/Ritam0"
        },
        {   id:3,
            icon:SiLeetcode,
            url:"https://leetcode.com/u/RitamMajumder/"
        },
        {   id:4,
            icon:FaLink,
            url:"https://ritammajumder.vercel.app/"
        },
        {   id:5,
            icon:SiGmail,
            url:"mailto:ritammajumder2025@gmail.com"
        },
    ];
  return (

    <div className='flex flex-col items-center justify-center text-[40px] pt-12 pb-24'>
      Links & Contacts
      <div className="mid-links flex flex-col lg:flex-row items-center justify-center gap-24 pt-12">
        <div className="image border-b-[2px] border-white rounded-[20px]" data-aos="fade-up" data-aos-duration="1500">
            <img src={Ritam} alt="" className='pt-2 w-[400px] h-[400px] lg:w-[550px] lg:h-[550px]' />
        </div>
        <div className="mid_right flex flex-col items-center justify-center">
         
        <div className="linkpart flex items-center justify-center gap-8">
            {Links.map((item,id)=>(
                <div key={id} className="links ">
                    <a target="_blank" href={item.url} className=''>{<item.icon size={40}/>}</a> 
                </div>
            ))}
        </div>
        <div className="thanks pt-6">
            Thank You...
        </div>
        {/* <div className="form pt-4 flex items-center justify-center flex-col">
    <form action="" className="pb-4 text-[20px]">
        <textarea className="w-[400px] h-[200px] border border-[2px] bg-transparent rounded-[15px] p-2 resize-none"></textarea>
    </form>
    <button className="flex items-center justify-center w-[200px] h-[30px] text-[20px] px-6 py-2 bg-white text-black rounded-lg font-bold transform hover:-translate-y-1 transition duration-400">
        Leave a Note
    </button>
</div> */}


           
        </div>
      </div>
    </div>
  )
}

export default Contact
