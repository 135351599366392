import React from 'react'

import { FaGithub } from "react-icons/fa";
const Projects = () => {
    const projects=[
        {
            Name: "Astro Pro",
            github: "https://astro-pro-ritam.vercel.app/",
            TechStack:"Next Js , Node Js , Tailwind CSS , TypeScript , Mongo DB",
            photo:"https://res.cloudinary.com/djyxyaqno/image/upload/v1725962528/Screenshot_2024-09-10_153107_sz4yob.png",
            description:"Fortune Favours The Brave",
        },
        {
            Name: "India Aura",
            github: "https://github.com/Ritam0/India-Aura",
            TechStack:"Mongo DB , Express Js , React Js , Node Js",
            photo:"https://res.cloudinary.com/djyxyaqno/image/upload/v1719517584/wskpcm5vhevejw21cvr2.png",
            description:"Dynamic Tourism Website with Chatbot Support",
        },
        {
            Name: "FusionI",
            github: "https://github.com/Ritam0/Diversion",
            TechStack:"React Js , Tailwind CSS , OpenAI API",
            photo:"https://res.cloudinary.com/djyxyaqno/image/upload/v1719518086/qxokugdja6erbunphxmo.png",
            description:"AI based SAAS platform",
        },
        {
            Name: "DigiCure",
            github: "https://github.com/Ritam0/status0_project",
            TechStack:"Mongo DB , Express Js , React Js , Node Js",
            photo:"https://res.cloudinary.com/djyxyaqno/image/upload/v1719691409/Screenshot_2024-06-30_013253_ejprkl.png",
            description:"All in One Medical App",
            ID:"EC101C"
        },
        {
            Name: "Fits You",
            github: "https://github.com/Ritam0/Fits-You",
            TechStack:"Mongo DB , Express Js , React Js , Node Js",
            photo:"https://res.cloudinary.com/djyxyaqno/image/upload/v1719691637/Screenshot_2023-07-16_133509_eabyw8.png",
            description:"Fitness App with multiple options to make you fit",
            ID:"EC102A"
        },
        {
            Name: "Card Matching",
            github: "https://github.com/Ritam0/Match-Card",
            TechStack:"HTML , CSS , JS",
            photo:"https://res.cloudinary.com/djyxyaqno/image/upload/v1719692147/Screenshot_2024-06-30_014420_tnvzfk.png",
            description:"Game",
            ID:"EC102B"
        },
        {
            Name: "Typing Speed Tester",
            github: "https://github.com/Ritam0/Typing-Speed-Tester",
            TechStack:"HTML , CSS , JS",
            photo:"https://res.cloudinary.com/djyxyaqno/image/upload/v1719692275/Screenshot_2024-06-30_014736_phehzj.png",
            description:"A simple app to test your typing speed",
            ID:"EC102C"
        },
        {
            Name: "Speech to Text Converter",
            github: "https://github.com/Ritam0/Speech-to-text-converter",
            TechStack:"React Js",
            photo:"https://res.cloudinary.com/djyxyaqno/image/upload/v1719692572/Screenshot_2024-06-30_015232_bjqmfa.png",
            description:"Speech to Text Converter",
            ID:"EC103A"
        }
    ]
  return (
    <div  className='template-bg pt-12 text-white'>
        
      <h1 className='text-[40px] flex items-center justify-center'>Projects</h1>
      <div className="listofprojects flex flex-wrap gap-20 items-center justify-center p-[20px]">
      {projects.map((project, id) => (
              <div className="bg-green" data-aos="zoom-in" data-aos-duration="1500">
                <div
                  key={id}
                  className="flex flex-col w-[100%] cursor-pointer text-white justify-center py-2 px-4 text-center items-center mt-12 rounded-[35px] shadow-2xl md:min-h-[340px]  card-item-div max-w-[700px] min-h-[260px] transition-transform hover:scale-105 hover:border-b-[6px] hover:border-b-white border border-gray-100"
                >
                  <img
                    src={project.photo}
                    alt="box_img"
                    className="w-[100%] mb-4 rounded-[25px] p-auto border-[2px] mt-[10px]"
                  />
                 
                  <p className="text-[32px] font-medium  mb-[5px] text-left" >
                    {project.Name}
                  </p>
                  <p className="text-[16px] font-medium mb-[5px] text-left" >
                    {project.description}
                  </p>
                  <p className="text-[16px] font-medium mb-[5px] text-left" >
                    {project.TechStack}
                  </p>
                  
                  <div className="flex mb-[20px] gap-8 items-center justify-center">
                    <a href={project.github} target="_blank">
                      <div className="">
                        <FaGithub size={40}/>
                      </div>
                      
                    </a>
                    
                  </div>
                </div>
              </div>
            ))}
      </div>
    </div>
  )
}

export default Projects
