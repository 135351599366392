import React from 'react'
import { Link } from 'react-router-dom';
const Navbar = () => {
  return (
    <div className="nav flex pt-6 pr-8">
    <a href="mailto:ritammajumder2025@gmail.com" target='_blank' className="ml-auto text-right">
      <button className="px-6 py-2 bg-white text-black rounded-lg font-bold transform hover:-translate-y-1 transition duration-400">
        Hire Me
      </button>
    </a>
  </div>
  )
}

export default Navbar
